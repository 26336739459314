import {
    ref,
    reactive,
    del,
    set,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";
import VueForm, { fieldProps, vueUtils } from "@lljj/vue-json-schema-form";
import { areas } from "@/langs/tw.json";

export default {
    name: "clientBookingSetting",
    components: {
        VueForm,
    },
    props: {},
    emits: [],
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();

        const loading = ref(false);

        const areaNames = Object.values(areas).map((value) => {
            return value.name;
        });
        const areaKeys = Object.keys(areas).map((key) => {
            return key;
        });

        const datasForm = ref({
            configurations: [
                {
                    name: "即刻快閃最少預訂時數",
                    value: "5",
                    key: "demand_hour_min_duration",
                    type: "single",
                },
                {
                    name: "即刻快閃最大預訂時數",
                    value: "24",
                    key: "demand_hour_max_duration",
                    type: "single",
                },
                {
                    name: "即刻快閃預設預訂時數",
                    value: "6",
                    key: "demand_hour_default_duration",
                    type: "single",
                },
                {
                    name: "即刻快閃最少預訂天數",
                    value: "1",
                    key: "demand_day_min_duration",
                    type: "single",
                },
                {
                    name: "即刻快閃最大預訂天數",
                    value: "7",
                    key: "demand_day_max_duration",
                    type: "single",
                },
                {
                    name: "即刻快閃預設預訂天數",
                    value: "1",
                    key: "demand_day_default_duration",
                    type: "single",
                },
                {
                    name: "即刻快閃每小時最低單價",
                    value: "2000",
                    key: "demand_hour_min_price",
                    type: "single",
                },
                {
                    name: "即刻快閃服務商報價每小時最低單價",
                    value: "1000",
                    key: "demand_hour_min_price_provider",
                    type: "single",
                },
                {
                    name: "即刻快閃每小時最高單價",
                    value: "99999",
                    key: "demand_hour_max_price",
                    type: "single",
                },
                {
                    name: "即刻快閃每天最低單價",
                    value: "20000",
                    key: "demand_day_min_price",
                    type: "single",
                },
                {
                    name: "即刻快閃每天最高單價",
                    value: "99999",
                    key: "demand_day_max_price",
                    type: "single",
                },
                {
                    name: "即刻快閃緩衝時間(單位小時)",
                    value: "0",
                    key: "demand_wait_hour",
                    type: "single",
                },
                {
                    name: "即刻快閃招募截止時間-活動開始時間往後延幾小時(單位小時)",
                    value: "24",
                    key: "demand_due_at_limit",
                    type: "single",
                },
                {
                    name: "即刻快閃最少招募人數",
                    value: "1",
                    key: "demand_provider_min_required",
                    type: "single",
                },
                {
                    name: "即刻快閃最多招募人數",
                    value: "10",
                    key: "demand_provider_max_required",
                    type: "single",
                },
                {
                    name: "即刻快閃開放區域",
                    optionValues: [
                        "TW-TPE",
                        "TW-NWT",
                        "TW-KEE",
                        "TW-TAO",
                        "TW-TXG",
                        "TW-KHH",
                    ],
                    key: "demand_open_areas",
                    type: "optionMore",
                },
                {
                    name: "即刻快閃每小時費用預設",
                    values: ["2000", "3000", "5000"],
                    key: "demand_default_hour_price",
                    type: "more",
                },
                {
                    name: "即刻快閃每天費用預設",
                    values: ["20000", "30000", "50000"],
                    key: "demand_default_day_price",
                    type: "more",
                },
            ],
        });

        const datasSchema = ref({
            title: "即刻快閃參數設定",
            required: ["dataList"],
            type: "object",
            properties: {
                configurations: {
                    type: "array",
                    minItems: 1,
                    items: {
                        type: "object",
                        required: ["value"],
                        properties: {
                            name: {
                                title: "參數名稱",
                                type: "string",
                                "err:required": "請輸入參數名稱",
                                default: "即刻快閃最少預訂時數",
                                readOnly: true,
                            },
                            type: {
                                tite: "是否為多參數值",
                                type: "string",
                                enum: ["single", "more", "optionMore"],
                                enumNames: [
                                    "單一參數",
                                    "多筆參數",
                                    "下拉選擇多筆參數",
                                ],
                            },
                            value: {
                                title: "參數值",
                                type: "string",
                                "err:required": "請輸入參數值",
                            },
                            values: {
                                title: "多筆參數值",
                                type: "array",
                                default: ["2000", "3000", "5000"],
                                minItems: 3,
                                maxItems: 3,
                                items: {
                                    title: "請輸入參數值",
                                    type: "string",
                                    default: "undefined",
                                },
                            },
                            optionValues: {
                                title: "多筆下拉參數值",
                                type: "array",
                                minItems: 1,
                                maxItems: 10,
                                items: {
                                    title: "請輸入參數值",
                                    type: "string",
                                    enum: areaKeys,
                                    enumNames: areaNames,
                                    default: null,
                                },
                            },
                            key: {
                                title: "前台對應 key",
                                type: "string",
                                "err:required": "請輸入前台對應key值",
                                readOnly: true,
                            },
                        },
                    },
                },
            },
        });

        const dataUiSchema = ref({
            configurations: {
                items: {
                    value: {
                        "ui:hidden": `{{parentFormData.type !== "single"}}`,
                    },
                    values: {
                        "ui:hidden": `{{parentFormData.type !== "more"}}`,
                    },
                    optionValues: {
                        "ui:hidden": `{{parentFormData.type !== "optionMore"}}`,
                    },
                },
            },
        });

        const customRule = ref(({ field, value, rootFormData, callback }) => {
            const rules = [
                {
                    rule: /dataList\.\d+\.url/,
                    validator(value, rootFormData) {
                        const regex =
                            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
                        if (!regex.test(value)) {
                            return "請輸入正確網址";
                        }
                    },
                },
                {
                    rule: /dataList\.\d+\.avatarImg/,
                    validator(value, rootFormData) {
                        const jsonDataParse = JSON.parse(value);
                        if (jsonDataParse.imgUrl === null) {
                            return "請上傳大頭照圖片";
                        }
                    },
                },
                {
                    rule: /dataList\.\d+\.imgUpload/,
                    validator(value, rootFormData) {
                        const jsonDataParse = JSON.parse(value);
                        if (jsonDataParse.imgUrl === null) {
                            return "請上傳背景圖片";
                        }
                    },
                },
                {
                    rule: /dataList\.\d+\.videoUpload/,
                    validator(value, rootFormData) {
                        const jsonDataParse = JSON.parse(value);
                        if (jsonDataParse.videoUrl === null) {
                            return "請上傳背景影片";
                        }
                    },
                },
            ];

            for (const ruleItem of rules) {
                // String | Regx
                if (
                    (String(ruleItem.rule) === ruleItem.rule &&
                        ruleItem.rule === field) ||
                    (Object.prototype.toString.call(ruleItem.rule) ===
                        "[object RegExp]" &&
                        ruleItem.rule.test(field))
                ) {
                    const error = ruleItem.validator(value, rootFormData);
                    // 停止檢查
                    if (error) return callback(error);
                }
            }
            return callback();
        });
        async function onSubmit(getFn, getForm) {
            try {
                await getFn().validate();
                await update(getForm);
            } catch (err) {
                console.log("validate err =>", err);
            }
        }

        /**
         * 取得預設值
         */
        async function getData() {
            try {
                const { data } = await proxy.$api.GetClientConfigurationsApi();
                if (data.configurations.length > 0) {
                    datasForm.value.configurations = data.configurations.map(
                        (item) => {
                            if (!Array.isArray(JSON.parse(item.value))) {
                                return {
                                    name: item.name,
                                    value: item.value,
                                    key: item.key,
                                    type: "single",
                                };
                            }
                            if (item.key === "demand_open_areas") {
                                return {
                                    name: item.name,
                                    optionValues: JSON.parse(item.value),
                                    key: item.key,
                                    type: "optionMore",
                                };
                            }
                            return {
                                name: item.name,
                                values: JSON.parse(item.value),
                                key: item.key,
                                type: "more",
                            };
                        }
                    );
                }
            } catch (err) {
                console.log("GetClientConfigurationsApi err =>", err);
            }
        }

        /**
         * 更新設定值
         */
        async function update(datas) {
            const sendData = {
                configurations: [],
            };
            sendData.configurations = datas.configurations.map((item) => {
                if (item.type === "single") {
                    return {
                        name: item.name,
                        value: item.value,
                        key: item.key,
                    };
                }
                if (item.key === "demand_open_areas") {
                    return {
                        name: item.name,
                        value: JSON.stringify(item.optionValues),
                        key: item.key,
                    };
                }
                return {
                    name: item.name,
                    value: JSON.stringify(item.values),
                    key: item.key,
                };
            });
            try {
                await proxy.$api.SetClientConfigurationsApi(sendData);
                await getData();
                proxy.$message({
                    type: "success",
                    message: "更新成功",
                });
            } catch (err) {
                console.log("SetClientConfigurationsApi err =>", err);
                proxy.$message({
                    type: "error",
                    message: "更新失敗",
                });
            }
        }

        onMounted(async () => {
            await getData();
        });
        return {
            datasForm,
            datasSchema,
            dataUiSchema,
            customRule,
            onSubmit,
            getData,
        };
    },
    render() {
        const scopedSlots = {
            default: (data) => (
                <div>
                    <pre
                        style={{
                            backgroundColor: "#eee",
                            overflowX: "scroll",
                            width: "800px",
                        }}
                    >
                        {/* {JSON.stringify(data.formData, null, 4)} */}
                    </pre>
                    <div class="flex justify-center">
                        <el-button
                            type="primary"
                            onClick={() =>
                                this.onSubmit(data.formRefFn, data.formData)
                            }
                        >
                            更新
                        </el-button>
                    </div>
                </div>
            ),
        };
        return (
            <div class="p-5 bg-white rounded-lg min-h-[500px]">
                <h2 class="my-5 text-2xl font-bold text-center">
                    前台訂單開單預設值
                </h2>

                <VueForm
                    vModel={this.datasForm}
                    class="text-red-500"
                    schema={this.datasSchema}
                    ui-schema={this.dataUiSchema}
                    custom-rule={this.customRule}
                    scopedSlots={scopedSlots}
                ></VueForm>
            </div>
        );
    },
};
